<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        label="选择员工"
        prop="userId"
        v-if="formType == 2"
      >
        <div class="slect_icon">
          <a-select
            v-model="form.userId"
            placeholder="请选择"
            style="width: 100%"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            allow-clear
          >
            <a-select-option
              v-for="(item, key) in userList"
              :key="key"
              :value="item.id"
            >{{ item.nickName }}</a-select-option>
          </a-select>
          <!-- <a-icon
            type="plus-circle"
            class="icon_cir"
            @click="$refs.CreateUser.handleAdd(1)"
          /> -->
        </div>
      </a-form-model-item>
      <a-form-model-item
        label="选择公司"
        prop="tenantId"
        v-if="formType == 1"
      >
        <div class="slect_icon">
          <a-select
            v-model="form.tenantId"
            placeholder="请选择"
            style="width: 100%"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            allow-clear
          >
            <a-select-option
              v-for="(item, key) in companyList"
              :key="key"
              :value="item.id"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-icon
            type="plus-circle"
            class="icon_cir"
            @click="$refs.CreatePany.handleAdd()"
          />
        </div>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            保存
          </a-button>
          <a-button
            type="dashed"
            @click="cancel"
          >
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <CreatePany
      ref="CreatePany"
      @ok="getCompany"
    ></CreatePany>
    <CreatePany
      ref="CreatePany"
      @ok="getCompany"
    ></CreatePany>
    <CreateUser
      ref="CreateUser"
      @ok="getUser"
      :statusOptions="dict.type['sys_normal_disable']"
      :sexOptions="dict.type['sys_user_sex']"
    ></CreateUser>
  </a-drawer>
</template>

<script>
import { userDevice, bmDevice } from '@/api/project/device'
import { listCompany } from '@/api/project/company'
import { listUser } from '@/api/system/user'
import { mapGetters } from 'vuex'
import CreatePany from '../../company/modules/CreateForm.vue'
import CreateUser from '../../../system/user/modules/CreateForm.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CreatePany,
    CreateUser
  },
  dicts: ['sys_normal_disable', 'sys_user_sex'],
  data () {
    return {
      previewVisible: false,
      fileList: [
      ],
      agentList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        tenantId: undefined,
        userId: undefined,
        idParamList: []
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        tenantId: [
          { required: true, message: '请选择公司', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '请选择员工', trigger: 'blur' }
        ]
      },
      userList: [],
      companyList: [],
      deviceTypeList: [],
      role: ''
    }
  },
  filters: {
  },
  created () {
    this.role = this.roles[0]
  },
  computed: {
    ...mapGetters([
      'roles',
      'tenantIds'
    ])
  },
  watch: {
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleJiaDai () {
      console.log(this.$refs.createForm, 'this.$refs.createFormthis.$refs.createFormthis.$refs.createForm==')
      this.$nextTick(() => {
        this.$refs.createForm.handleAdd()
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        tenantId: undefined,
        userId: undefined,
        idParamList: []
      }
    },
    // 查看所有的用户
    getUser () {
      const query = {
        userType: 2,
        tenantId: this.role === 'GS' ? this.tenantIds : ''
      }
      listUser(query).then(res => {
        console.log(res.data, '大大大大大')
        this.userList = res.data
      })
    },
    // 查看所有的公司公司
    getCompany () {
      listCompany().then(res => {
        this.companyList = res.data
      })
    },
    /** 新增按钮操作 */
    handleAdd (e, row) {
      this.reset()
      this.formType = e
      this.open = true
      console.log(row, 'dddsdfsadfasdfddd')
      this.form.idParamList = row
      if (e === 1) {
        this.formTitle = '分配公司'
        this.getCompany()
      } else {
        this.formTitle = '分配员工'
        this.getUser()
      }
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.formType == 1) {
            bmDevice(this.form).then(response => {
              this.$message.success(
                '绑定成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            userDevice(this.form).then(response => {
              this.$message.success(
                '绑定成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}

.slect_icon {
  display: flex;
  align-items: center;

  .icon_cir {
    margin-left: 30px;
    font-size: 25px;
    cursor: pointer;
  }
}
</style>
