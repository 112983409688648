import { render, staticRenderFns } from "./CreateFen.vue?vue&type=template&id=34baa449&scoped=true"
import script from "./CreateFen.vue?vue&type=script&lang=js"
export * from "./CreateFen.vue?vue&type=script&lang=js"
import style0 from "./CreateFen.vue?vue&type=style&index=0&id=34baa449&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34baa449",
  null
  
)

export default component.exports